import React from "react"
import {
  SiteName,
  Paragon,
  DoublePica,
  Pica,
  BodyCopy,
  Brevier,
} from "../utils/styledComponents"

import BaseLayout from "../components/base"
import SEO from "../components/seo"

class Home extends React.Component {
  render() {
    return (
      <BaseLayout location={this.props.location}>
        <SEO title="Theme" />
        <SiteName>SiteName</SiteName>
        <Paragon>Paragon</Paragon>
        <DoublePica>DoublePica</DoublePica>
        <Pica>Pica</Pica>
        <BodyCopy>BodyCopy</BodyCopy>
        <Brevier>Brevier</Brevier>
      </BaseLayout>
    )
  }
}

export default Home
